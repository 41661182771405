"use client";

import { T } from "@tolgee/react";
import { useAuthContext } from "./authProvider";

export default function Messages() {
    const { errorMessage, successMessage } = useAuthContext();

    if (successMessage) {
        return (
            <p className="-mb-4 -mt-2 text-sm font-medium text-center text-green-600">
                <T
                    keyName={successMessage.keyName}
                    defaultValue={successMessage.defaultValue}
                />
            </p>
        );
    } else if (errorMessage) {
        return (
            <p className="-mb-4 -mt-2 text-sm font-medium text-center text-red-600">
                <T
                    keyName={errorMessage.keyName}
                    defaultValue={errorMessage.defaultValue}
                />
            </p>
        );
    }
}
