"use client";

import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { T } from "@tolgee/react";
import AuthHeader from "../../_components/header";
import { useAuthContext } from "../../_components/authProvider";
import { FormEvent, useState } from "react";
import { LoaderCircle } from "lucide-react";
import { signIn } from "next-auth/react";
import Messages from "../../_components/messages";
import { homePath } from "@/features/auth/utils";

export default function LoginForm() {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const { loading, setLoading, setErrorMessage } = useAuthContext();

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        setErrorMessage(null);
        setLoading(true);

        try {
            await signIn("credentials", {
                email,
                password,
                redirectTo: homePath,
            });
        } catch (err) {
            setErrorMessage({
                keyName: "auth.errors.sign-in.failed",
                defaultValue: "הפרטים שהוזנו אינם תקינים",
            });
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="p-6 md:p-8">
            <div className="flex flex-col gap-6">
                <AuthHeader
                    title={{
                        keyName: "login.title",
                        defaultValue: "התחברות",
                    }}
                    description={{
                        keyName: "login.description",
                        defaultValue: "המשיכו עם גוגל או מלאו את הפרטים",
                    }}
                />

                <div className="grid gap-2">
                    <Label htmlFor="email">
                        <T keyName="login.email" defaultValue="מייל" />
                    </Label>
                    <Input
                        disabled={loading}
                        id="email"
                        type="email"
                        placeholder="name@example.com"
                        value={email}
                        onChange={(e) => {
                            setErrorMessage(null);
                            setEmail(e.target.value.toLowerCase());
                        }}
                        required
                    />
                </div>
                <div className="grid gap-2">
                    <div className="flex items-center">
                        <Label htmlFor="password">
                            <T keyName="login.password" defaultValue="סיסמה" />
                        </Label>
                        <a
                            href="/auth/reset-password"
                            className="ms-auto text-sm underline-offset-2 hover:underline"
                        >
                            <T
                                keyName="login.forgot-password"
                                defaultValue="שכחת סיסמה?"
                            />
                        </a>
                    </div>
                    <Input
                        disabled={loading}
                        id="password"
                        type="password"
                        required
                        value={password}
                        onChange={(e) => {
                            setErrorMessage(null);
                            setPassword(e.target.value);
                        }}
                    />
                </div>

                <Messages />

                <div className="flex flex-col w-full mt-2 gap-4">
                    <Button disabled={loading} type="submit" className="w-full">
                        {loading ? (
                            <LoaderCircle className="size-4 animate-spin" />
                        ) : (
                            <T keyName="login.login-btn" defaultValue="כניסה" />
                        )}
                    </Button>
                </div>

                <div className="text-center text-sm">
                    אין לך עדיין חשבון?{" "}
                    <a
                        href="/auth/signup"
                        className="underline underline-offset-4"
                    >
                        הרשמה
                    </a>
                </div>
            </div>
        </form>
    );
}
