"use client";

import { Button } from "@/components/ui/button";
import { T } from "@tolgee/react";
import { FcGoogle } from "react-icons/fc";
import { useAuthContext } from "./authProvider";
import { signIn } from "next-auth/react";

export default function AuthHeader({
    title,
    description,
}: {
    title: {
        keyName: string;
        defaultValue: string;
    };
    description: {
        keyName: string;
        defaultValue: string;
    };
}) {
    const { loading, setLoading } = useAuthContext();

    const signInWithGoogle = async () => {
        setLoading(true);
        await signIn("google", {
            redirect: false,
        });
    };

    return (
        <>
            <div className="flex flex-col items-center text-center">
                <h1 className="text-2xl font-bold">
                    <T
                        keyName={title.keyName}
                        defaultValue={title.defaultValue}
                    />
                </h1>
                <p className="text-balance text-muted-foreground">
                    <T
                        keyName={description.keyName}
                        defaultValue={description.defaultValue}
                    />
                </p>
            </div>

            <div className="grid grid-cols-1">
                <Button
                    disabled={loading}
                    onClick={signInWithGoogle}
                    variant="outline"
                    className="w-full"
                    type="button"
                >
                    <FcGoogle className="size-5 me-2" />
                    <T
                        keyName="auth.continue-with-google-btn"
                        defaultValue="המשיכו עם גוגל"
                    />
                </Button>
            </div>

            <div className="relative text-center text-sm after:absolute after:inset-0 after:top-1/2 after:z-0 after:flex after:items-center after:border-t after:border-border">
                <span className="relative z-10 bg-background px-2 text-muted-foreground">
                    <T keyName="auth.or" defaultValue="או" />
                </span>
            </div>
        </>
    );
}
